import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import './Tips.css';

interface TipsProps {
}

const Tips: React.FC<TipsProps> = () => {
	return (
		<div className='Tips-container'>
			<div className='Tips-title'>
				<Parallax opacity={[0, 1, 'easeInOut']}>
					<Parallax opacity={[5.5, 0, 'easeInOut']}>
						<h2>Užitočné tipy</h2>
					</Parallax>	
				</Parallax>	
			</div>
			<div className='Tips'>
				<div className='Kvety'>
					<Parallax opacity={[0, 6.5, 'easeInOut']}>
						<Parallax opacity={[2.5, 0, 'easeInOut']}>
								<div className='TipsIcon'>
									<img src="/assets/icons/noFlowers.svg" style={{height: '100%', marginBottom: 10, opacity: 0.7}} alt="Ikona no flowers" />
								</div>	
								<p className='Paragraph1'>Ak si mal/a v pláne nám priniesť kvety, tak si to prosím ešte raz dôkladne premysli. Kvetov bude na našej svadbe naozaj dostatočné množstvo.</p>
						</Parallax>
					</Parallax>
				</div>
				<div className='Kolace'>
					<Parallax opacity={[0, 6.5, 'easeInOut']}>
						<Parallax opacity={[2.5, 0, 'easeInOut']}>
							<div className='TipsIcon'>
								<img src="/assets/icons/noCakes.svg" style={{height: '100%', opacity: 0.7}} alt="Ikona no cakes" />
							</div>
							<p className='Paragraph1'>Koláče ani torty nám tiež nemusíš nosiť. Po každej svadbe ostáva množstvo jedla navyše. Aj takto sa to snažíme minimalizovať.</p>
						</Parallax>
					</Parallax>		
				</div>
				<div className='Ubytko'>
					<Parallax opacity={[0, 3.5, 'easeInOut']}>
						<Parallax opacity={[3.5, 0, 'easeInOut']}>
							<div className='TipsIcon'>
								<img src="/assets/icons/accommodation.svg" style={{height: '100%', opacity: 0.7}} alt="Ikona accommodation" />
							</div>
							<p className='Paragraph1'>Ak si nám dal vedieť, ubytovanie máš zabezpečené a o šoférovanie sa tiež nemusíš obávať. Všetky ubytovacie kapacity sú v dostatočnej blízkosti na to, aby si sa tam dostal/a po vlastných, aj po 
								svadbe.</p>
						</Parallax>
					</Parallax>			
				</div>
			</div>
		</div>
	);
};

export default Tips;
