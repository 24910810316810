import React from 'react';
import './Footer.css';

interface FooterProps {
}

const Footer: React.FC<FooterProps> = () => {
	return (
		<footer className='FooterContainer'>
			<p>Photo by <a href="https://unsplash.com/@georgiadelotz?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Georgia de Lotz</a> on <a href="https://unsplash.com/photos/PQ3b0aqDZRg?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
			<p><a href="https://www.flaticon.com/free-icons/location" title="location icons">Location icons created by pongsakornRed - Flaticon</a></p>
			<p>© Šimon Domonkoš 2023</p>

		</footer>
	);
};

export default Footer;
