// Import the functions you need from the SDKs you need
import { initializeApp } from '@firebase/app';
import { getFirestore } from '@firebase/firestore';

export let db;


export const getDb = () => {
    if (!db) {
        const firebaseConfig = {
            apiKey: "AIzaSyApcL5vXp4qj-MgzpkkMHKeX2HyP_e2jmw",
            authDomain: "wedding-web-b2bed.firebaseapp.com",
            databaseURL: "https://wedding-web-b2bed-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "wedding-web-b2bed",
            storageBucket: "wedding-web-b2bed.appspot.com",
            messagingSenderId: "250406995303",
            appId: "1:250406995303:web:4f24eae269e84263049bc2",
            measurementId: "G-CLR75D69B5"
        };
        
            // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        db = getFirestore(app);
    }

    return db;
}


