import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import './Intro.css';

interface IntroProps {
}

const Intro: React.FC<IntroProps> = () => {
	return (
		<div className='Introduction-container'>
			<div className='Introduction'>
				<Parallax opacity={[0, 1.5, 'easeInOut']}>
					<Parallax opacity={[5.5, 0, 'easeInOut']}>
						<p>Milý svadobčan, na tejto stránke nájdeš všetky podstatné informácie o našej svadbe, ale aj užitočné tipy, tak neváhaj listovať ďalej.</p>
					</Parallax>
				</Parallax>
			</div>
			<div className='ChurchInfo'>
				<Parallax opacity={[0, 2, 'easeInOut']}>
					<Parallax opacity={[3, 0, 'easeInOut']}>
						<p><strong>Sobáš</strong> sa uskutoční v kostole sv.Martina v Hliníku nad Hronom <strong>23.9.2023 o 15:00</strong>.</p>
						<a href='https://goo.gl/maps/T2dgL7WKDewSdoRj6' rel="noreferrer" target="_blank"><img className='ShowOnMapIcon' src="/assets/icons/gps-navigation.svg" alt="gps-navigation" /></a>
						<p className='Note'>Kostol nájdeš aj na mape</p>
					</Parallax>
				</Parallax>
			</div>
			<div className='WeddingInfo'>
				<Parallax opacity={[0, 7, 'easeInOut']}>
					<Parallax opacity={[3, 0, 'easeInOut']}>
						<p>Po sobáši sa presunieme na <strong>svadobnú hostinu</strong> do <strong>kultúrneho domu</strong>, ktorý je od kostola vzdialený asi 500 metrov.</p>
						<a href='https://goo.gl/maps/FzUvsmKPyBJbfwCs6' rel="noreferrer" target="_blank"><img className='ShowOnMapIcon' src="/assets/icons/gps-navigation.svg" alt="gps-navigation" /></a>
						<p className='Note'>Kultúrny dom nájdeš aj na mape</p>
					</Parallax>
				</Parallax>
			</div>
		</div>
	);
};

export default Intro;
