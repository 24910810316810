import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import './App.css';
import Contributors from './Pages/Contributors/Contributors';
import Footer from './Pages/Footer/Footer';
import GiftTips from './Pages/GiftTips/GiftTips';
import Header from './Pages/Header/Header'
import Intro from './Pages/Intro/Intro';
import Tips from './Pages/Tips/Tips';
import BibleQuote from './Pages/BibleQuote/BibleQuote';
import {isDesktop } from 'react-device-detect';
import { useState } from 'react';
import Modal from './Pages/GiftTips/Modal';
import {db} from "./firebaseConfig/db.mjs";
import { doc, updateDoc } from '@firebase/firestore';

function App() {
  function getCurrentDimension(){
    return {
      	width: window.innerWidth,
      	height: window.innerHeight
    }
  }
  const screenSize= getCurrentDimension();
  
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState();
  const [link, setLink] = useState();
  const [id, setId] = useState();


  const updateDb = async(id: any, reserved: boolean) => {
    const giftTipDoc = doc(db, "giftTips", id);
    const newField = {Reserved: reserved};
    updateDoc(giftTipDoc, newField);
  }
  
  

  return (
    <div className="App">
      {isDesktop ?
        <ParallaxProvider>
          {showModal &&
            <Modal setShowModal={setShowModal} Item={item} Link={link} updateDb={updateDb} id={id}/>
          }
          <div className="App">
            <div style={{height: 0}}></div>
            <Parallax speed={-screenSize.height*1.8}>
              <div className='background'>
              </div>
            </Parallax>
            <Header/>
            <BibleQuote/>
            <Intro/>
            <Tips/>
            <GiftTips setShowModal={setShowModal} setId={setId} setItem={setItem} setLink={setLink}/>
            <Contributors/>
            <Footer/>
          </div>
        </ParallaxProvider>
      :
      <div style={{height: '1vh'}}>
        <p style={{fontSize: 24}} className="Paragraph1">Mobilnú verziu sme zatiaľ nevytvorili, preto ťa prosíme, aby si si našu stránku otvoril na počítači alebo notebooku.</p>
      </div>}
    </div>
  );
}

export default App;
