import { getDocs, collection } from "firebase/firestore";
import {getDb} from "../../firebaseConfig/db.mjs";

const collection_name = "giftTips"

export const findAll = async () => {
    const doc_refs = await getDocs(collection(getDb(), collection_name))

    const res = []

    doc_refs.forEach(giftTip => {
        res.push({
            id: giftTip.id, 
            ...giftTip.data()
        })
    })

    return res
}