import React from 'react';
import GiftTipsList from './giftTipsList';
import './GiftTips.css';

interface GiftTipsProps {
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	setId: React.Dispatch<React.SetStateAction<undefined>>;
	setItem: React.Dispatch<React.SetStateAction<undefined>>;
	setLink: React.Dispatch<React.SetStateAction<undefined>>;
}

const GiftTips: React.FC<GiftTipsProps> = ({setShowModal, setId, setItem, setLink}) => {
	return (
		<div className='SvadobneDary-WIP' style={{justifySelf: 'center', alignSelf: 'center'}}>
			<h2>Tipy na svadobné dary</h2>
			<p className='howTo'>Každú položku môžeš rozkliknúť. Otvorí sa možnosť ju rezervovať alebo si pozrieť konkrétnu verziu, ktorá sa nám páči najviac.</p>
			<div className='GiftTipItemsContainer'>
				<div className='GiftTipItems'>
					<GiftTipsList setShowModal={setShowModal} setId={setId} setItem={setItem} setLink={setLink}/>
				</div>
			</div>
		</div>
	);
};

export default GiftTips;
