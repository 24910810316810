
import { useState, useEffect } from "react";
import { findAll } from '../../crud/services/giftTips.mjs';
import './GiftTips.css';


function GiftTipsList({setShowModal, setId, setItem, setLink}) {
    const [giftTips, setGiftTips] = useState([]);

    const fetchData = async () => {

        const res = await findAll()

        setGiftTips([...res])
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            {giftTips.length > 0 && giftTips.map(giftTip => (
                
                    <div className='Item' key={giftTip.id} onClick={() => {setShowModal(true); setItem(giftTip.Text); setLink(giftTip.Link); setId(giftTip.id)}}>
                        {!giftTip.Reserved && <div className='ItemText'>
                            <div className="ItemTextContainer">
                                <input type="text" className='ItemText' style={{pointerEvents: 'none'}} value={giftTip.Text}/>
                            </div>
                        </div>}
                    </div>
            ))}
        </>
                
    )
}

export default GiftTipsList






