import React from 'react';
import './Header.css';

interface HeaderProps {
}

const Header: React.FC<HeaderProps> = () => {

	return (
			<div className='App-header'>
				<h1 className='HeaderOfHeaders'>Tatiana a Šimon</h1>
			</div>
	);
};

export default Header;
