import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import './BibleQuote.css';

interface AppProps {
}

const App: React.FC<AppProps> = () => {
    return (
        <div className='BibleQuoteContainer'>
            <Parallax opacity={[0, 2.5, 'easeInOut']}>
                <Parallax opacity={[2.5, 0, 'easeInOut']}>
                    <div className='BibleQuote'>
                        <p className='BibleQuoteParagraph'>On odpovedal: „Nečítali ste, že Stvoriteľ ich od počiatku ako muža a ženu stvoril a povedal: Preto muž opustí otca i matku a pripúta sa k svojej manželke a budú dvaja v jednom tele?
                            A tak už nie sú dvaja, ale jedno telo. Čo teda Boh spojil, nech človek nerozlučuje.“</p>
                        <p className='BibleQuoteParagraph1'>Mt 19:4-6</p>
                    </div>
                </Parallax>
            </Parallax>    
        </div>
    );
};

export default App;
