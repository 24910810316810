import React, { useState } from 'react';
import './GiftTips.css';
import './Modal.css';

interface ModalProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    updateDb: (id: any, reserved: boolean) => Promise<void>;
    id: undefined;
    Item: undefined;
    Link: undefined;
}


const Modal: React.FC<ModalProps> = ({setShowModal, updateDb, id, Item, Link}) => {
    const [agree, setAgree] = useState(false);



    return (
        <div className="modalBackground">
            <div className="modalContainer">
                <button className="closeButton" onClick={() => setShowModal(false)}> X </button>
                <input className="modalItem" type="text" style={{pointerEvents: 'none'}} value={Item}/>
                <p className="modalDescription">Túto položku môžeš rezervovať alebo prejsť na link, kde nájdeš verziu, ktorá sa nám páči.</p>
                <div className="checkboxContainer">
                    <input type="checkbox" className="checkbox" onClick={() => setAgree(!agree)} />
                    <p>Som si vedomý/á, že rezerváciu nebude možné zrušiť</p>
                </div>
                <div className="buttonContainer">
                    <button className="linkButton"><a href={Link} target="_blank" rel="noreferrer noopener" style={{fontWeight: 100}}>Link</a></button>
                    <button className="reserveButton" disabled={!agree} onClick={() => {
                        updateDb(id, true); 
                        setTimeout(() => { 
                            window.location.reload();
                            setShowModal(false);
                        }, 500);
                    }}>Rezervovať</button>
                </div>
                <p className="disclaimer" >Ak by si predsa len potreboval/a zrušiť rezerváciu, prosím ozvi sa nám.</p>
            </div>
        </div>
    );
}

export default Modal;