import React from 'react';
import './Contributors.css';

interface ContributorsProps {
}

const Contributors: React.FC<ContributorsProps> = () => {
	return (
		<div className='Contributors-container'>
			<h2>Shoutout</h2>
			<div className='Contributors'>
				<div className='WeddingSisters'>
					<div className='ContributorsLogo'>
						<a href='https://www.weddingsisters.sk/' target='_blank' rel="noopener noreferrer" style={{height: '100%'}}>
							<img src="/assets/logos/wedding-sisters-logo.svg" style={{width: '100%'}} alt="Logo WeddingSisters" />
						</a>
					</div>
					<p className='ContributorsParagraph'>Keď svadobné foto tak weddingsisters.sk. Petra a Jana majú viac ako 15 rokov skúseností fotenia svadieb, fashion editoriálov, reklám či obálok rôznych časopisov. Dokonalý prístup, 
					profesionalita a perfektný výsledok.</p>
				</div>
				<div className='Gastro'>
					<div className='ContributorsLogo'>
						<a href='https://www.facebook.com/lenka.veterna' target={'_blank'} rel="noopener noreferrer" style={{height: '100%'}}>
							<img src="/assets/icons/chef-icon.svg" style={{height: '100%'}} alt="Chef icon" />
						</a>	
					</div>
					<p className='ContributorsParagraph'>Lenka a Ivan Sondíkovci nie sú žiadny masový catering. Varenie je ich koníčkom. Do svojich jedál vkladajú jedinečnosť, originalitu a kopu lásky. Aj preto ti pripravia dokonalé menu podľa tvojich predstáv. Máš špeciálny recept alebo pokrm, ktorý ti žiadny catering 
					nepripraví? Lenka s Ivanom sa o to postarajú.</p>
				</div>
				<div className='DivoZel'>
					<div className='ContributorsLogo'>
						<a href='https://www.instagram.com/divo_zel/' target='_blank' rel="noopener noreferrer" style={{height: '100%'}}>
							<img src="/assets/logos/Divozel-logo.svg" style={{height: '100%'}} alt="Logo Divozel" />
						</a>	
					</div>
					<p className='ContributorsParagraph'>Keď skoro na jar hľadáš pod posledným snehom prvé kvety, keď zbieraš púpavu a myslíš pri tom na to aby ostalo aj včelám, keď je pre teba drobná malina v lese tá najchutnejšia pochúťka, 
					keď počúvaš babku na lúke a ukladáš si do pamäte každú vôňu bylinky s ktorou ťa zoznámila,  keď v deravom strome vidíš kráľovstvo škriatkov, keď v najkrivšom konáriku vidíš dokonalú krásu. Keď tvoríš s hlbokou úctou a láskou k prírode.</p>
					<p className='ContributorsParagraph'>Je to Divozel.</p>
				</div>
				<div className='DJFabo'>
					<div className='ContributorsLogo'>
						<a href='http://fabomusic.eu/' target={'_blank'} rel="noopener noreferrer" style={{height: '100%'}}>
							<img src="/assets/logos/Fabo-logo.png" style={{height: '100%'}} alt="Logo DJFabo" />
						</a>
					</div>
					<p className='ContributorsParagraph'>Fabo Music zabezpečuje hudobnú a svetelnú produkciu pre všetky vekové kategórie - svadby, plesy, stužkové, firemné akcie, diskotéky, športové akcie… Zabezpečuje tiež prenájom koncertných stanov,
					pódií či inej svetelnej a zvukovej techniky ale aj výpomoc pri tvorbe programu pre tvoje podujatie.</p>
				</div>
				<div className='Babik'>
					<div className='ContributorsLogo'>
						<a href='https://www.instagram.com/__babik_/' target={'_blank'} rel="noopener noreferrer" style={{height: '100%'}}>
							<img src="/assets/logos/BABIK_LOGO.svg" style={{height: '100%'}} alt="Logo Babik" />
						</a>
					</div>
					<p className='ContributorsParagraph'>Zuzka Babíková</p>
					<p className='ContributorsParagraph'>„Som grafická dizajnérka s vkusom prevažne pre minimalizmus. Vizuál grafickych prác, ktoré tvorím je síce jednoduchý, no moderný a pre ľudské oko pôvabný...Mám jasný cieľ čím chcem do sveta prispieť. 
					Je to umenie a to, kým som.“</p>
				</div>
			</div>

		</div>
	);
};

export default Contributors;
